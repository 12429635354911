import AddressLookup from 'components/AddressLookup'
import Button from './ui/Button'
import FormPageIndicator from './FormPageIndicator'
import Input from './ui/Input'
import React from 'react'
import pullAll from 'lodash/pullAll'
import sentenceCase from 'sentence-case'
import { Field } from 'formik'
import { NOT_REQUIRED_FIELDS, ADDRESS_FIELDS } from './../utils/constants'
import { withContentContainer } from '../ContentContainerContext'
import { withRouter } from 'react-router-dom'

const landlordFields = [
  'name',
  'email',
  'phoneNumber',
  'addressLine_1',
  'addressLine_2',
  'addressLine_3',
  'addressLine_4',
  'city',
  'postcode',
]

class Landlord extends React.Component {
  render() {
    const {
      editing,
      errors,
      focusFirstErrorInContent,
      history,
      setTouched,
      touched,
    } = this.props

    const fieldsToTouch = { landlord: {} }
    landlordFields.forEach(field => {
      if (!NOT_REQUIRED_FIELDS.includes(field)) {
        fieldsToTouch.landlord[field] = true
      }
    })

    const isTouched =
      touched &&
      touched.landlord &&
      Object.keys(touched.landlord).length >=
        Object.keys(fieldsToTouch.landlord).length

    const fieldsWithoutAddress = pullAll([...landlordFields], ADDRESS_FIELDS)

    return (
      <>
        <div className="wrap-content u-push-large">
          <h1>Landlord details</h1>
          <p>Tell us who is named on the AST as the landlord.</p>
          {fieldsWithoutAddress.map((currentField, index) => (
            <Field
              key={`landlord${index}`}
              name={`landlord.${currentField}`}
              render={({ field }) => (
                <Input
                  {...field}
                  fullWidth={true}
                  error={
                    touched.landlord &&
                    touched.landlord[currentField] &&
                    errors &&
                    errors.landlord
                      ? errors.landlord[currentField]
                      : null
                  }
                  type={currentField === 'email' ? 'email' : 'text'}
                  hint={
                    currentField === 'name'
                      ? 'This could be a person, people or a company.'
                      : null
                  }
                  placeholder={sentenceCase(currentField)}
                  label={sentenceCase(currentField)}
                  autocomplete="name"
                />
              )}
            />
          ))}
          <AddressLookup
            prefix="landlord"
            searchPlaceholder="Landlord address (first line or postcode)"
          />
          <div className="flex-column">
            {editing ? (
              <Button
                className="u-push-3 u-block"
                disabled={!!errors.landlord}
                fullWidth
                onClick={() => history.push('/form/review')}
                variant="main"
              >
                Update
              </Button>
            ) : (
              <>
                <Button
                  className="u-push-3 u-block"
                  fullWidth
                  onClick={() => {
                    if (!isTouched || errors.landlord) {
                      setTouched(fieldsToTouch)
                      focusFirstErrorInContent()
                    } else {
                      history.push('/form/references')
                    }
                  }}
                  variant="main"
                >
                  Next
                </Button>
                <FormPageIndicator />
              </>
            )}
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(withContentContainer(Landlord))
