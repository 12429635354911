import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Input from './Input'

const inputStateShape = {
  backspaceCount: 0,
  nextRef: null,
  pLen: 0,
  prevRef: null,
}

export default class DateEntry extends React.Component {
  inputStates = {
    day: { ...inputStateShape },
    month: { ...inputStateShape },
    year: { ...inputStateShape },
  }

  constructor(props) {
    super()
    this.dayRef = React.createRef()
    this.monthRef = React.createRef()
    this.yearRef = React.createRef()
    this.state = {
      day: '',
      month: '',
      year: '',
      value: props.value,
    }
  }

  handleChange = e => {
    const { name, value, max } = e.target
    this.updateState({ [name]: value.substring(0, max.length) })
  }

  handleKeyUp = e => {
    const { name, max } = e.target
    let { value } = e.target
    const currState = this.inputStates[name]
    switch (e.keyCode) {
      case 8: // Backspace
        if (value.length === 0 && currState.prevRef) {
          if (currState.backspaceCount > 1) {
            currState.prevRef.current.fieldRef.current.focus()
            currState.backspaceCount = 0
          }
          currState.backspaceCount++
        }
        break
      case 46: // Delete
        return
      default:
        break
    }

    if (value.length > max.length) {
      this.updateState({ [name]: value.substring(0, max.length) })
    }
    if (
      ((currState.pLen < 2 && value.length === 2) || value.length > 2) &&
      currState.nextRef
    ) {
      currState.nextRef.current.fieldRef.current.focus()
    }

    currState.pLen = value.length
  }

  handleKeyDown = e => {
    // Stop 'e' being typed into number fields
    if (e.keyCode === 69) e.preventDefault()
  }

  handleFocus = e => {
    const { name, value } = e.target
    const currState = this.inputStates[name]
    currState.pLen = value.length
    setTimeout(() => this.updateState({}, 'onFocus'))
  }

  handleBlur = e => {
    const { max, min, name } = e.target
    let { value } = e.target

    if (value !== '') {
      if (Number(value) < Number(min)) value = min
      if (Number(value) > Number(max)) value = max

      if (max.length === 4 && value.length <= 2) {
        while (value.length < 2) {
          value = '0' + value
        }
        value = Number(value) < 30 ? '20' + value : '19' + value
      }
    }

    this.updateState({ [name]: value }, 'onBlur')
  }

  updateState = (state, callback) => {
    const { name, onChange } = this.props

    const nextState = { ...this.state, ...state }
    let nextValue = `${nextState.year}-${nextState.month}-${nextState.day}`
    if (nextValue === '--') nextValue = null
    this.setState({
      ...nextState,
      value: nextValue,
    })
    if (onChange) {
      onChange({ target: { name, value: nextValue } })
    }
    if (this.props[callback]) {
      this.props[callback]({ target: { name, value: nextValue } })
    }
  }

  componentDidMount(props) {
    const { value } = this.state

    this.inputStates.day.nextRef = this.monthRef
    this.inputStates.month.prevRef = this.dayRef
    this.inputStates.month.nextRef = this.yearRef
    this.inputStates.year.prevRef = this.monthRef

    if (value) {
      const [year, month, day] = value.split('-')
      this.setState({ day, month, year })
    }
  }

  render() {
    const {
      className,
      disabled,
      error,
      fullWidth,
      hint,
      hiviz,
      label,
      presentation,
      value,
    } = this.props
    const [year, month, day] = value ? value.split('-') : ['', '', '']
    const propSpreadFirst = { error, hint, hiviz }
    const propSpread = { disabled, fullWidth, presentation }
    const handlerSpread = {
      onKeyDown: this.handleKeyDown,
      onKeyUp: this.handleKeyUp,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onChange: this.handleChange,
    }

    const classes = classNames(
      {
        'is-disabled': disabled,
        'is-error': !!error,
      },
      className
    )

    return (
      <div>
        <Input
          className={classNames(
            'Input-dateentry-field Input--fixed-label',
            classes
          )}
          label={label}
          max={31}
          min={1}
          name="day"
          placeholder="DD"
          ref={this.dayRef}
          style={{ overflow: 'visible' }}
          type="number"
          value={day}
          {...propSpreadFirst}
          {...propSpread}
          {...handlerSpread}
        />
        <Input
          className={classNames('Input-dateentry-field', classes)}
          max={12}
          min={1}
          name="month"
          placeholder="MM"
          ref={this.monthRef}
          type="number"
          value={month}
          {...propSpread}
          {...handlerSpread}
        />
        <Input
          className={classNames('Input-dateentry-field-year', classes)}
          max={2100}
          min={1900}
          name="year"
          placeholder="YYYY"
          ref={this.yearRef}
          type="number"
          value={year}
          {...propSpread}
          {...handlerSpread}
        />
      </div>
    )
  }
}

DateEntry.defaultProps = {
  disabled: false,
  hiviz: false,
  presentation: false,
  type: 'text',
  value: '',
}

DateEntry.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  hint: PropTypes.string,
  hiviz: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  presentation: PropTypes.bool,
  type: PropTypes.string,
}
