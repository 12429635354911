import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { withRouter, Link } from 'react-router-dom'

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  width: 100%;
  min-height: fit-content;
`

const ContactDetails = styled.div`
  flex: 0 1 50%;
`

const StyledLink = styled(Link)`
  color: #f45372;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: fit-content;
  margin: 0;
`

const Wrap = styled.div`
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-content: center;
`

const Header = ({ location: { pathname } }) => (
  <StyledHeader>
    <Wrap>
      <SVG src="/assets/logo-med.svg" style={{ height: 72 }} />
      {pathname === '/market-property' && (
        <StyledLink to="/find-tenants">
          <i className="Icons-prev-step" />
          Back
        </StyledLink>
      )}
      {pathname === '/tell-agent' && (
        <StyledLink to="/">
          <i className="Icons-prev-step" />
          Back
        </StyledLink>
      )}
    </Wrap>
    <div className="u-flex-filler" />
    <ContactDetails>
      Need help? <span style={{ whiteSpace: 'nowrap' }}>0333 200 2543</span>
    </ContactDetails>
  </StyledHeader>
)

export default withRouter(Header)
