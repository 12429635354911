import React from 'react'
export const ContentContainerContext = React.createContext()

// HOC to consume the ContentContainerContext
export const withContentContainer = Component => props => (
  <ContentContainerContext.Consumer>
    {context => {
      return <Component {...props} {...context} />
    }}
  </ContentContainerContext.Consumer>
)
