import React from 'react'
import Button from './ui/Button'
import Header from './Header'

const Agreement = ({ history }) => {
  return (
    <>
      <Header />
      <div className="wrap-content u-push-large">
        <h2 className="u-push-3">Keep this in mind</h2>
        <p className="u-push-large">
          When offering the choice of Zero Deposit, you need to be impartial and
          your tenants must be free to choose between a Zero Deposit Guarantee
          or a traditional deposit.
        </p>
        <p className="u-push-3">
          If your tenants have questions, or want your opinion, simply direct
          them to our website.
        </p>
        <p className="u-push-3">
          You can advertise your properties as being “Available with Zero
          Deposit” but you can’t promote Zero Deposit to your tenants.
        </p>
        <p className="u-push-3">
          You must only send us information you have collected for the purposes
          of renting your property.
        </p>
        <Button
          className="u-push-large"
          fullWidth
          onClick={() => history.push('/find-tenants')}
          variant="main"
        >
          Agree
        </Button>
      </div>
    </>
  )
}

export default Agreement
