import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default class Select extends React.Component {
  constructor(props) {
    super()
    this.state = {
      isFocused: false,
      label: null,
    }
  }

  handleChange = e => {
    const selectedValue = e.nativeEvent.srcElement.value
    this.setState({ valueLabel: selectedValue, value: selectedValue })

    const { onChange } = this.props
    if (onChange) onChange(e)
  }

  addFocus = e => {
    this.setState({ isFocused: true })
  }

  removeFocus = e => {
    this.setState({ isFocused: false })
  }

  componentWillMount() {
    const { children, value } = this.props

    if (value !== '') {
      const result = children.find(child => child.props.value === value)
      if (result) {
        this.setState({ valueLabel: result.props.children })
      }
    }
  }

  render() {
    const { isFocused, valueLabel } = this.state
    const {
      children,
      className,
      disabled,
      error,
      fullWidth,
      hint,
      hiviz,
      icon,
      label,
      name,
      onBlur,
      onFocus,
      placeholder,
      value,
    } = this.props
    const classes = classNames(
      'Select',
      {
        'Select--span': fullWidth,
        'Select-hiviz': hiviz,
        'is-disabled': disabled,
        'is-error': !!error,
        'is-focused': isFocused,
        'has-value': !!value && !disabled,
        [`Icons-${icon}`]: !!icon,
      },
      className
    )

    return (
      <div className={classes}>
        <div className="Select-field">
          <div className="Select-container">
            <div className="Select-value">{valueLabel || placeholder}</div>
            <select
              className="Select-select"
              id={name}
              onChange={this.handleChange}
              onFocus={this.addFocus}
              onBlur={this.removeFocus}
              {...{ name, disabled, value, onBlur, onFocus }}
            >
              <option value="">Select an option</option>
              {children}
            </select>
            <label className="Select-label" htmlFor={name}>
              {label}
            </label>
            <div className="Select-border" />
          </div>
        </div>
        <div className="Select-helpers" data-error={error} data-hint={hint} />
      </div>
    )
  }
}

Select.defaultProps = {
  disabled: false,
  fullWidth: false,
  hiviz: false,
  initialValue: '',
  placeholder: 'Select an option',
}

Select.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  hint: PropTypes.string,
  hiviz: PropTypes.bool,
  icon: PropTypes.string,
  initialValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
}
