import React from 'react'
import Header from './Header'
import styled from 'styled-components'

const StyledP = styled.p`
  font-size: 11px;
`

const MarketProperty = ({ history }) => {
  return (
    <>
      <Header history={history} />
      <div className="wrap-content u-push-large">
        <h2 className="u-push-3">
          Marketing <br /> your property
        </h2>
        <p className="u-push-large">
          You can attract up to <strong> 26% more* </strong>
          views when you advertise your property with Zero Deposit.
        </p>
        <p className="u-push-2">
          To get started, simply list your property as “Available with Zero
          Deposit”.
        </p>
        <p className="u-push-2">
          When you have found and referenced your tenants, return to{' '}
          <a href="https://www.zerodeposit.com"> zerodeposit.com </a> and offer
          them the choice of renting deposit free.
        </p>
        <StyledP className="u-push-large">
          *Source: Properties advertised with Zero Deposit, by Oakfield Estate
          Agents, received a higher number of views compared to those without
          Zero Deposit.
        </StyledP>
      </div>
    </>
  )
}

export default MarketProperty
