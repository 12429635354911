import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class CheckBox extends React.Component {
  render() {
    const {
      checked,
      children,
      className,
      hint,
      name,
      error,
      onChange,
    } = this.props
    const classes = classNames(
      'CheckBox',
      {
        'is-error': !!error,
      },
      className
    )
    return (
      <div className={classes}>
        <input
          className="CheckBox-input"
          type="checkbox"
          id={name}
          {...{ checked, onChange, name }}
        />
        <label className="CheckBox-label" htmlFor={name}>
          {children}
        </label>
        <div className="CheckBox-helpers" data-error={error} data-hint={hint} />
      </div>
    )
  }
}

CheckBox.defaultProps = {
  checked: false,
}

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  hint: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}
