import React from 'react'
import PointList, { PointListItem } from './ui/PointList'
import Header from './Header'

const OfferSent = () => (
  <>
    <Header />
    <div className="wrap-content u-push-large">
      <h1>Offer Sent</h1>
      <p>
        Your tenants will be offered the choice of Zero Deposit. Here’s what
        happens next.
      </p>
      <div>
        <PointList className="u-push-large">
          <PointListItem>
            <i className="Icons-tick Icons-tick--white" />
            <b>Tell us about the tenancy</b> <br />
            Thank you for sending us the tenancy information.
          </PointListItem>
          <PointListItem after={2}>
            <b>Tenants choose Zero Deposit </b> <br />
            We’ll email your tenants with everything they need to purchase a
            Zero Deposit Guarantee.
          </PointListItem>
          <PointListItem after={3}>
            <b>Sign the tenant update document</b> <br />
            As soon as your tenants purchase, we’ll let you know. You should
            have all parties sign the{' '}
            <a
              href="/assets/ZD-Tenancy-Update.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Tenancy Update Document
            </a>{' '}
            alongside your AST.
          </PointListItem>
        </PointList>
      </div>
    </div>
  </>
)
export default OfferSent
