export const BUTTON_VARIANT_TYPES = [
  'primary',
  'primary-forced',
  'tertiary',
  'agents',
  'lld',
  'link',
  'main',
]

export const TENANT_COUNT_MIN = 1
export const TENANT_COUNT_MAX = 8

export const TENANT_BLANK = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  dateOfBirth: '',
}

export const ADDRESS_FIELDS = [
  'addressLine_1',
  'addressLine_2',
  'addressLine_3',
  'addressLine_4',
  'city',
  'postcode',
]

export const GUARANTOR_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'addressLine_1',
  'addressLine_2',
  'addressLine_3',
  'addressLine_4',
  'city',
  'postcode',
]

export const NOT_REQUIRED_FIELDS = [
  'addressLine_2',
  'addressLine_3',
  'addressLine_4',
]
