import Button from 'components/ui/Button'
import Input from 'components/ui/Input'
import PropTypes from 'prop-types'
import React from 'react'
import debounce from 'lodash/debounce'
import get from 'keypather/get'
import qs from 'qs'
import sentenceCase from 'sentence-case'
import styled from 'styled-components'
import { ADDRESS_FIELDS } from 'utils/constants'
import { Field, connect } from 'formik'

const MODES = { SEARCH: 0, PREVIEW: 1, MANUAL: 2 }

/*
Autofill address happens in two steps, namely:
  - Find: where we get a list of candidates for the search query defined by the
    user
  - Retrieve: where we get the details of the property once the user clicks on
    one of the outcomes above.

https://www.loqate.com/developers/api/capture/
*/
const API_BASE = 'https://api.addressy.com/Capture/Interactive'
const API_FIND_URL = `${API_BASE}/Find/v1.00/json3.ws`
const API_RETRIEVE_URL = `${API_BASE}/Retrieve/v1.20/json3.ws`

const Lookup = styled.div`
  position: relative;
`

const Results = styled.div`
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  left: 0;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  right: 0;
  top: calc(100% - 54px);
  z-index: 10;
`

const Result = styled.a`
  background-color: white;
  color: #444;
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding: 15px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #eee;
  }

  &.current {
    background-color: #ededed;
  }

  ${Results}:hover &.current {
    background-color: unset;

    &:hover {
      background-color: #ededed;
    }
  }
`

const AddressPreview = styled.div`
  font-size: 18px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: #ddd 1px dashed;
`

const AddressPreviewLabel = styled.div`
  color: #232129;
  font-size: 14px;
`

const AddressButton = styled(Button)`
  margin-right: 0 !important;
  min-width: unset;
  padding: 6px 14px;
  position: absolute;
  right: 0;
  top: 16px;
  width: auto;

  &:before {
    float: right;
    margin-left: 6px;
    margin-right: 0 !important;
  }
`

const EditLink = styled.button`
  color: #f45372;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  text-align: right;

  &:hover {
    text-decoration: underline;
  }
`

class AddressLookup extends React.Component {
  state = {
    addressQuery: '',
    mode: MODES.SEARCH,
    currentItem: -1,
    showResults: false,
    results: [],
    ...ADDRESS_FIELDS,
  }

  constructor(props) {
    super(props)
    this.queryRef = React.createRef()
    this.resultsRef = React.createRef()
  }

  lookupAddress = debounce(query => {
    if (query.length < 2) return

    const querystringDefaults = {
      Key: process.env.REACT_APP_LOQATE_API_KEY,
      Countries: 'GB',
      Limit: 20,
    }

    const querystring = {
      ...querystringDefaults,
      Text: query,
    }
    const url = `${API_FIND_URL}?${qs.stringify(querystring)}`

    const handleAddressResponse = results => {
      const { Items } = results

      this.setState({
        currentItem: -1,
        showResults: Items.length > 0,
        results: Items.filter(item => item.Type === 'Address'),
      })
    }

    fetch(url, { method: 'GET' })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          this.setState({ mode: MODES.MANUAL })
        }
      })
      .then(results => {
        if (results.Items[0].Error) {
          this.setState({ mode: MODES.MANUAL })
        } else if (results.Items[0].Type === 'Address') {
          handleAddressResponse(results)
        } else {
          const querystring = {
            ...querystringDefaults,
            Text: query,
            Container: results.Items[0].Id,
          }

          const url = `${API_FIND_URL}?${qs.stringify(querystring)}`
          fetch(url, { method: 'GET' })
            .then(response => response.json())
            .then(results => handleAddressResponse(results))
        }
      })
  }, 400)

  handleQueryChange = e => {
    this.setState({ addressQuery: e.target.value })
    this.lookupAddress(e.target.value)
  }

  handleQueryBlur = e => {
    const {
      formik: { handleBlur },
    } = this.props

    handleBlur(e)

    setTimeout(() => this.setState({ showResults: false }), 400)
    e.preventDefault()
  }

  handleQueryFocus = e => {
    this.setState({
      currentItem: -1,
      showResults: this.state.results.length > 0,
    })
  }

  handleClickAddress = address => e => {
    this.retrieveAddressToState(address.Id)
  }

  setFieldValues = fields => {
    const {
      prefix,
      formik: { setFieldTouched, setFieldValue },
    } = this.props
    Object.keys(fields).forEach(field => {
      const fieldName = `${prefix}${prefix !== '' ? '.' : ''}${field}`
      setFieldValue(fieldName, fields[field])
      setFieldTouched(fieldName, true)
    })
  }

  retrieveAddressToState = addressId => {
    const fillNextState = results => {
      const nextState = {
        addressLine_1: results.Items[0].Line1,
        addressLine_2: results.Items[0].Line2,
        addressLine_3: results.Items[0].Line3,
        addressLine_4: results.Items[0].Line4,
        postcode: results.Items[0].PostalCode,
        city: results.Items[0].City,
      }

      this.setFieldValues(nextState)
      this.setState({ ...nextState, mode: MODES.PREVIEW })
    }

    const querystring = {
      Key: process.env.REACT_APP_LOQATE_API_KEY,
      Id: addressId,
    }
    const url = `${API_RETRIEVE_URL}?${qs.stringify(querystring)}`

    fetch(url, { method: 'GET' })
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          this.setState({ mode: MODES.MANUAL })
        }
      })
      .then(results => {
        if (results.Items[0].Error) {
          this.setState({ mode: MODES.MANUAL })
        } else {
          fillNextState(results)
        }
      })
  }

  handleClearClick = e => {
    e.preventDefault()
    const nextState = {}
    ADDRESS_FIELDS.forEach(key => (nextState[key] = ''))
    this.setFieldValues(nextState)
    this.setState(
      { ...nextState, addressQuery: '', results: [], mode: MODES.SEARCH },
      () => {
        this.queryRef.current.focus()
      }
    )
  }

  handleEnterManually = e => {
    e.preventDefault()
    this.setState({ mode: MODES.MANUAL })
  }

  handleKeyPress = e => {
    const { currentItem, results } = this.state
    switch (e.charCode) {
      case 13: // Enter
        if (currentItem > -1) {
          this.handleClickAddress(results[currentItem])()
        }
        e.preventDefault()
        break
      default:
        break
    }
  }

  updateResultsScrollPosition = () => {
    const { currentItem } = this.state
    const ref = this.resultsRef.current
    if (ref && ref.children[currentItem]) {
      const { offsetHeight, scrollTop } = ref
      const { offsetTop: itemTop, offsetHeight: itemHeight } = ref.children[
        currentItem
      ]
      const relTop = itemTop - scrollTop
      const relBottom = offsetHeight - (relTop + itemHeight)

      if (relTop < 0) ref.scrollTop += relTop
      else if (relBottom < 0) ref.scrollTop -= relBottom
    }
  }

  handleKeyDown = e => {
    const { currentItem, results } = this.state
    let next
    if (!results || results.length === 0) return

    switch (e.keyCode) {
      case 27: // ESC
        this.setState({ showResults: false })
        break
      case 38: // UP
        next = currentItem <= 0 ? results.length - 1 : currentItem - 1
        this.setState({ currentItem: next }, () => {
          this.updateResultsScrollPosition()
        })
        e.preventDefault()
        break
      case 40: // DOWN
        next = currentItem >= results.length - 1 ? 0 : currentItem + 1
        this.setState({ currentItem: next }, () => {
          this.updateResultsScrollPosition()
        })
        e.preventDefault()
        break
      default:
        break
    }
  }

  componentDidMount() {
    const {
      prefix,
      formik: { values },
    } = this.props

    const initValues = get(values, prefix)

    if (prefix.endsWith('guarantor')) {
      const nextState = {}
      ADDRESS_FIELDS.forEach(key => (nextState[key] = ''))
      this.setFieldValues(nextState)
      this.setState({ ...nextState, addressQuery: '', results: [] })
    }

    if (initValues && initValues.addressLine_1) {
      const nextState = {}
      ADDRESS_FIELDS.forEach(key => {
        nextState[key] = initValues[key]
      })
      this.setState({
        ...nextState,
        mode: MODES.PREVIEW,
      })

      // deferred to give formik a chance to update :s
      setTimeout(() => this.checkPreviewHasValidFields())
    }
  }

  checkPreviewHasValidFields() {
    const {
      prefix,
      formik: { errors, setFieldTouched },
    } = this.props

    const validateFields = ['addressLine_1', 'city', 'postcode']
    validateFields.forEach(field => {
      setFieldTouched(`${prefix}${prefix !== '' ? '.' : ''}${field}`)
    })

    const initErrors = get(errors, prefix)

    const hasErrors =
      initErrors &&
      !!(initErrors.addressLine_1 || initErrors.city || initErrors.postcode)

    this.setState({
      mode: hasErrors ? MODES.MANUAL : MODES.PREVIEW,
    })
  }

  render() {
    const {
      className,
      formik: { touched, errors },
      hint,
      prefix,
      searchPlaceholder,
    } = this.props
    const { currentItem, mode, addressQuery, results, showResults } = this.state

    const touchedPrefix = get(touched, prefix)
    const errorsPrefix = get(errors, prefix)

    return (
      <div className={className}>
        {mode === MODES.SEARCH && (
          <>
            <Lookup>
              <Input
                fullWidth
                icon="search"
                inputProps={{ autoComplete: 'no', ref: this.queryRef }}
                label="Address"
                name={`${prefix}${prefix !== '' ? '.' : ''}addressLine_1`}
                onBlur={this.handleQueryBlur}
                onChange={this.handleQueryChange}
                onFocus={this.handleQueryFocus}
                placeholder={searchPlaceholder}
                hint={hint || ' '}
                value={addressQuery}
                onKeyDown={this.handleKeyDown}
                onKeyPress={this.handleKeyPress}
                error={
                  touchedPrefix &&
                  touchedPrefix.addressLine_1 &&
                  errorsPrefix &&
                  errorsPrefix.addressLine_1
                    ? errorsPrefix.addressLine_1
                    : null
                }
              />
              {showResults && (
                <Results ref={this.resultsRef}>
                  {results.map((result, index) => (
                    <Result
                      key={index}
                      onClick={this.handleClickAddress(result)}
                      className={currentItem === index ? 'current' : null}
                    >
                      {result.Text + ', ' + result.Description}
                    </Result>
                  ))}
                </Results>
              )}
            </Lookup>
            <EditLink type="button" onClick={this.handleEnterManually}>
              Enter address manually
            </EditLink>
          </>
        )}
        {mode === MODES.PREVIEW && (
          <>
            <AddressPreview>
              <AddressPreviewLabel className="u-caption">
                Address
              </AddressPreviewLabel>
              <AddressButton
                size="small"
                icon="close"
                onClick={this.handleClearClick}
              >
                Clear
              </AddressButton>
              {ADDRESS_FIELDS.map(field => {
                return this.state[field] ? (
                  <React.Fragment key={field}>
                    {this.state[field]}
                    <br />
                  </React.Fragment>
                ) : null
              })}
            </AddressPreview>
            <EditLink
              type="button"
              className="u-push-2"
              onClick={this.handleEnterManually}
            >
              Edit address
            </EditLink>
          </>
        )}
        <div style={{ display: mode === MODES.MANUAL ? 'block' : 'none' }}>
          {ADDRESS_FIELDS.map((currentField, index) => (
            <Field
              key={`${prefix}${index}`}
              name={`${prefix}${prefix !== '' ? '.' : ''}${currentField}`}
              render={({ field }) => (
                <Input
                  {...field}
                  fullWidth={true}
                  error={
                    touchedPrefix &&
                    touchedPrefix[currentField] &&
                    errorsPrefix &&
                    errorsPrefix[currentField]
                      ? errorsPrefix[currentField]
                      : null
                  }
                  placeholder={sentenceCase(currentField)}
                  label={sentenceCase(currentField)}
                />
              )}
            />
          ))}
          <EditLink
            type="button"
            className="u-push-2"
            onClick={this.handleClearClick}
          >
            Address search
          </EditLink>
        </div>
      </div>
    )
  }
}

AddressLookup.propTypes = {
  className: PropTypes.string,
  hint: PropTypes.string,
  prefix: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string,
}

export default connect(AddressLookup)
