import AddressLookup from 'components/AddressLookup'
import React from 'react'
import pullAll from 'lodash/pullAll'
import sentenceCase from 'sentence-case'
import { Field } from 'formik'
import { ADDRESS_FIELDS, GUARANTOR_FIELDS } from 'utils/constants'

import Input from './ui/Input'

class GuarantorFields extends React.Component {
  render() {
    const { touched, errors, index } = this.props

    const isTouched =
      touched &&
      touched.tenants &&
      touched.tenants[index] &&
      touched.tenants[index].guarantor

    const isError =
      errors &&
      errors.tenants &&
      errors.tenants[index] &&
      errors.tenants[index].guarantor

    const fieldsWithoutAddress = pullAll([...GUARANTOR_FIELDS], ADDRESS_FIELDS)

    return (
      <>
        {fieldsWithoutAddress.map((guarantorField, guarantorIndex) => {
          const hint =
            guarantorField === 'email'
              ? 'Please note: Guarantee information is sent to this address'
              : null
          return (
            <Field
              key={`guarantor${guarantorIndex}`}
              name={`tenants[${index}]guarantor.${guarantorField}`}
              render={({ field }) => (
                <Input
                  {...field}
                  fullWidth
                  className={guarantorIndex === 0 ? 'u-push-3' : null}
                  placeholder={sentenceCase(guarantorField)}
                  label={sentenceCase(guarantorField)}
                  hint={hint}
                  type={guarantorField === 'email' ? 'email' : 'text'}
                  error={
                    isTouched &&
                    touched.tenants[index].guarantor[guarantorField] &&
                    isError
                      ? isError[guarantorField]
                      : null
                  }
                />
              )}
            />
          )
        })}
        <AddressLookup
          prefix={`tenants[${index}].guarantor`}
          hint="First line or postcode"
          searchPlaceholder="Guarantor address"
        />
      </>
    )
  }
}

export default GuarantorFields
