import React from 'react'
import RadioGroup, { Radio } from './ui/RadioGroup'
import Header from './Header'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import Button from './ui/Button'

const SectionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: #ddd 1px dashed;
  }
`
const StyledCol = styled.div`
  width: 65%;
`

const StyledRadioLabel = styled.strong`
  font-size: 20px;
`

class PreScreen extends React.Component {
  constructor() {
    super()
    this.state = {
      answer: null,
    }
  }

  handleChange = e => {
    this.setState({ answer: e.target.value })
  }

  render() {
    const { history, location } = this.props
    const { title, radioLabel, text } = this.props.content
    let { linkTo } = this.props.content
    if (this.state.answer === true) {
      linkTo = linkTo[0]
    } else if (this.state.answer === false) {
      linkTo = linkTo[1]
    }

    return (
      <>
        <Header />
        <div className="wrap-content u-push-large">
          <h5>{title}</h5>
          <div>
            <RadioGroup name="userType" onChange={this.handleChange}>
              <div>
                <SectionRow className="u-push-large">
                  <Radio value={true} id="radio1">
                    <StyledRadioLabel>{radioLabel[0]}</StyledRadioLabel>
                  </Radio>
                  <StyledCol>{text[0]}</StyledCol>
                </SectionRow>
                <SectionRow className="u-push-3">
                  <Radio value={false} id="radio2">
                    <StyledRadioLabel>{radioLabel[1]}</StyledRadioLabel>
                  </Radio>

                  <StyledCol>
                    {text[1]}
                    {location.pathname === '/' ? (
                      <p>
                        No agent?{' '}
                        <a href="https://www.zerodeposit.com/find-an-agent/">
                          Find a Zero Deposit partner.
                        </a>
                      </p>
                    ) : null}
                  </StyledCol>
                </SectionRow>
              </div>
            </RadioGroup>
          </div>
          <Button
            className="u-push-large"
            fullWidth
            onClick={() => history.push(linkTo)}
            variant="main"
            disabled={this.state.answer === null}
          >
            Next
          </Button>
        </div>
      </>
    )
  }
}
export default withRouter(PreScreen)
