import React from 'react'
import PageIndicator from 'components/ui/PageIndicator'
import { withRouter } from 'react-router-dom'

const routes = [
  '/form/landlord',
  '/form/references',
  '/form/tenants',
  '/form/property',
  '/form/review',
]

const FormPageIndicator = props => {
  const {
    location: { pathname },
  } = props

  return <PageIndicator routes={routes} path={pathname} />
}

export default withRouter(FormPageIndicator)
