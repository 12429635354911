import 'core-js/es6/map'
import 'core-js/es6/set'
import 'whatwg-fetch'
import 'raf/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

if (process.env.NODE_ENV === 'development') {
  require('dotenv').config()
}

ReactDOM.render(<App />, document.getElementById('root'))
