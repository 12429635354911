import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default class Input extends React.Component {
  constructor() {
    super()
    this.fieldRef = React.createRef()
  }

  render() {
    const {
      className,
      disabled,
      error,
      fullWidth,
      hint,
      hiviz,
      icon,
      label,
      name,
      max,
      min,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      placeholder,
      presentation,
      style,
      type,
      value,
      inputProps,
    } = this.props

    const classes = classNames(
      'Input',
      {
        'Input--present': presentation,
        'Input--span': fullWidth,
        'Input-hiviz': hiviz,
        'is-disabled': disabled,
        'is-error': !!error,
        [`Icons-${icon}`]: !!icon,
      },
      className
    )

    return (
      <div className={classes} style={style}>
        <div className="Input-field">
          <input
            className="Input-input"
            id={name}
            placeholder={placeholder || label}
            ref={this.fieldRef}
            onChange={onChange}
            value={value}
            {...{
              disabled,
              max,
              min,
              name,
              onBlur,
              onFocus,
              onKeyDown,
              onKeyPress,
              onKeyUp,
              type,
            }}
            {...inputProps}
          />
          <label className="Input-label" htmlFor={name}>
            {label}
          </label>
          <div className="Input-border" />
        </div>
        <div className="Input-helpers" data-error={error} data-hint={hint} />
      </div>
    )
  }
}

Input.defaultProps = {
  disabled: false,
  fullWidth: false,
  hiviz: false,
  presentation: false,
  type: 'text',
  value: '',
}

Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  hint: PropTypes.string,
  hiviz: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  presentation: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
}
