import styled from 'styled-components'

const PointList = styled.ul`
  list-style-type: none;
  padding: 0;
`
export default PointList

export const PointListItem = styled.li`
  padding: 0 0 30px 45px;
  position: relative;
  margin: 0;

  &:not(:last-child)::before {
    border-left: #ececec 2px solid;
    content: '';
    height: calc(100% + 10px);
    left: 15px;
    position: absolute;
    top: 10px;
    width: 2px;
  }

  &::after {
    align-items: center;
    background-color: ${props => (props.after ? 'white' : '#558E17')}
    border-radius: 50%;
    border: ${props => (props.after ? '#dadada 1px solid' : '')};
    box-shadow: 0 0 0 8px white;
    content: '${props => props.after}';
    display: flex;
    height: 30px;
    justify-content: center;
    left: 0;
    padding: 2px 0 0 1px;
    position: absolute;
    top: -6px;
    width: 30px;
  }
`
