import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default class RadioGroup extends React.Component {
  getChildContext() {
    const { name, selectedValue, onChange } = this.props
    return {
      radioGroup: {
        name,
        selectedValue,
        onChange,
      },
    }
  }

  render() {
    const { children, className, error, hint } = this.props
    const classes = classNames(
      'RadioGroup',
      {
        'is-error': !!error,
      },
      className
    )
    return (
      <div className={classes} role="radiogroup">
        {children}
        <div
          className="RadioGroup-helpers"
          data-error={error}
          data-hint={hint}
        />
      </div>
    )
  }
}

RadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  hint: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}

RadioGroup.childContextTypes = {
  radioGroup: PropTypes.object,
}

// Single Radio component, must be a child of RadioGroup
export class Radio extends React.Component {
  render() {
    const { name, selectedValue, onChange } = this.context.radioGroup
    const { className, children, id, style, value } = this.props
    const contextState = {}
    if (selectedValue !== undefined) {
      contextState.checked = value === selectedValue
    }
    if (typeof onChange === 'function') {
      contextState.onChange = e =>
        onChange({ target: { type: 'radio', name, value } })
    }
    const classes = classNames('Radio', className)

    return (
      <div className={classes} style={style}>
        <input
          className="Radio-input"
          type="radio"
          {...{ id, name, value }}
          {...contextState}
        />
        <label className="Radio-label" htmlFor={id}>
          {children}
        </label>
      </div>
    )
  }
}

Radio.contextTypes = {
  radioGroup: PropTypes.object,
}

Radio.defaultProps = {
  checked: false,
}

Radio.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}
