import * as Yup from 'yup'

var invalidDate = new Date('')
const MAXIMUM_TENANCY_START_DAYS = 270

let today = new Date()
today.setHours(0)
today.setMinutes(0)
today.setSeconds(0)
today.setMilliseconds(0)

const maxTenancyStartDays = new Date()
maxTenancyStartDays.setDate(
  maxTenancyStartDays.getDate() + MAXIMUM_TENANCY_START_DAYS
)

const date18YearsAgo = new Date()
date18YearsAgo.setFullYear(date18YearsAgo.getFullYear() - 18)

const date130YearsAgo = new Date()
date130YearsAgo.setFullYear(date130YearsAgo.getFullYear() - 130)

const minTenancyStartDate = new Date('1990-01-01')

const maxMonthlyRent = 8337.33
const minMonthlyRent = 20.8

// eslint-disable-next-line
const phoneRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
// eslint-disable-next-line
const postcodeRegex = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/
// eslint-disable-next-line
const emailRegex = /^[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/

const tagsArgs = [
  /^[^<;||>%*^{}\\~[\]@()+]+$/i,
  { message: 'Please remove any invalid characters: ^ <> ; | % * + {} [] () @ ~', excludeEmptyString: true },
]

// check a day is set in addition to the original validation
const dateCheck = function (value, originalValue) {
  var day = originalValue ? originalValue.split('-')[2] : null
  if (day && this.isType(value) && validDaysPerMonth(originalValue)) {
    return value
  } else {
    return invalidDate
  }
}

function validDaysPerMonth(date) {
  var monthsWith30Days = ['4', '04', '6', '06', '9', '09', '11']
  var day = date.split('-')[2]
  var month = date.split('-')[1]

  return day > 30 && monthsWith30Days.includes(month)
    ? false
    : day > 29 && (month === '2' || month === '02')
    ? false
    : true
}

const validationSchema = Yup.object().shape({
  landlord: Yup.object().shape({
    name: Yup.string()
      .matches(...tagsArgs)
      .min(2, 'Name is required')
      .max(45, 'Name must be under 45 characters'),
    email: Yup.string()
      .required('E-mail is required')
      .matches(emailRegex, 'E-mail is not valid'),
    phoneNumber: Yup.string()
      .matches(phoneRegex, 'You must enter a valid phone number')
      .required('Phone number is required'),
    addressLine_1: Yup.string()
      .matches(...tagsArgs)
      .min(2, 'Address is required')
      .max(255, 'The max length is 255'),
    addressLine_2: Yup.string()
      .matches(...tagsArgs)
      .max(255, 'The max length is 255'),
    addressLine_3: Yup.string()
      .matches(...tagsArgs)
      .max(255, 'The max length is 255'),
    addressLine_4: Yup.string()
      .matches(...tagsArgs)
      .max(255, 'The max length is 255'),
    city: Yup.string()
      .matches(...tagsArgs)
      .min(2, 'City is required')
      .max(100, 'The max length is 100'),
    postcode: Yup.string()
      .min(2, 'Postcode is required')
      .matches(postcodeRegex, 'Valid postcode is required'),
  }),
  tenants: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .matches(...tagsArgs)
        .min(2, 'First name is required')
        .max(45, 'First name must be under 45 characters'),
      lastName: Yup.string()
        .matches(...tagsArgs)
        .min(2, 'Last name is required')
        .max(45, 'Last name must be under 45 characters'),
      dateOfBirth: Yup.date()
        .typeError('A valid date of birth is required')
        .required('Date of Birth is required')
        .max(date18YearsAgo, 'Your tenants must be over 18 years of age')
        .min(date130YearsAgo, 'Your must enter a valid birth date')
        .transform(dateCheck),
      phoneNumber: Yup.string()
        .matches(phoneRegex, 'You must enter a valid phone number')
        .required('Phone is required'),
      email: Yup.string()
        .required('E-mail is required')
        .matches(emailRegex, 'E-mail is not valid'),
      guarantor: Yup.lazy(value => {
        if (value !== undefined) {
          return Yup.object().shape({
            firstName: Yup.string()
              .matches(...tagsArgs)
              .min(2, 'First name is required')
              .max(45, 'First name must be under 45 characters'),
            lastName: Yup.string()
              .matches(...tagsArgs)
              .min(2, 'Last name is required')
              .max(45, 'First name must be under 45 characters'),
            email: Yup.string()
              .required('Email is required')
              .matches(emailRegex, 'E-mail is not valid'),
            phoneNumber: Yup.string()
              .required('Phone number is required')
              .matches(phoneRegex, 'You must enter a valid phone number'),
            addressLine_1: Yup.string()
              .matches(...tagsArgs)
              .min(2, 'Address is required')
              .max(255, 'The max length is 255'),
            addressLine_2: Yup.string()
              .matches(...tagsArgs)
              .max(255, 'The max length is 255'),
            addressLine_3: Yup.string()
              .matches(...tagsArgs)
              .max(255, 'The max length is 255'),
            addressLine_4: Yup.string()
              .matches(...tagsArgs)
              .max(255, 'The max length is 255'),
            city: Yup.string()
              .min(2, 'City is required')
              .max(100, 'The max length is 100'),
            postcode: Yup.string()
              .min(2, 'Postcode is required')
              .matches(postcodeRegex, 'Valid postcode is required'),
          })
        }
        return Yup.mixed().notRequired()
      }),
    })
  ),
  passedReferences: Yup.mixed().required('This field is required'),
  referenceCompany: Yup.string().required('This field is required'),
  addressLine_1: Yup.string()
    .required('Address is required')
    .min(2, 'Address is required')
    .max(255, 'The max length is 255'),
  addressLine_2: Yup.string()
    .matches(...tagsArgs)
    .max(255, 'The max length is 255'),
  addressLine_3: Yup.string()
    .matches(...tagsArgs)
    .max(255, 'The max length is 255'),
  addressLine_4: Yup.string()
    .matches(...tagsArgs)
    .max(255, 'The max length is 255'),
  city: Yup.string()
    .matches(...tagsArgs)
    .required('City is required')
    .min(2, 'City is required')
    .max(100, 'The max length is 100'),
  postcode: Yup.string()
    .required('Postcode is required')
    .min(2, 'Postcode is required')
    .matches(postcodeRegex, 'Valid postcode is required'),
  amount: Yup.number()
    .typeError('You must enter a valid monthly rent')
    .required('Monthly rent is required')
    .max(maxMonthlyRent, `Maximum monthly rent is £ ${maxMonthlyRent}`)
    .min(minMonthlyRent, `Minimum monthly rent is £ ${minMonthlyRent}`)
    .nullable(),
  tenancyStart: Yup.date()
    .typeError('A valid date is required')
    .required('This field is required')
    .min(
      minTenancyStartDate,
      `Tenancy start date should be after 1st January 1990`
    )
    .max(
      maxTenancyStartDays,
      `Tenancy start date should be within ${MAXIMUM_TENANCY_START_DAYS} days`
    )
    .transform(dateCheck),
})

export default validationSchema
