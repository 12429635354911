import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const imagePaths = {
  '/': 'pic1.png',
  '/tell-agent': 'pic2.png',
  '/agreement': 'pic2.png',
  '/find-tenants': 'pic3.png',
  '/landing': 'pic4.png',
  '/form/landlord': 'pic5.png',
  '/form/references': 'pic6.png',
  '/form/tenants': 'pic7.png',
  '/form/property': 'pic8.png',
  '/form/review': 'pic9.png',
  '/offersent': 'pic10.png',
}

const Panel = styled.div`
  height: 100%;
  position: relative;
`

class Image extends React.Component {
  state = {
    preloadImage: null,
  }

  constructor(props) {
    super(props)
    this.timer = null
  }

  componentDidMount() {
    const { nextImage } = this.props
    if (nextImage) {
      //preload next image
      this.timer = setTimeout(() => {
        this.setState({ preloadImage: nextImage })
      }, 1000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { className } = this.props
    const { preloadImage } = this.state
    return (
      <div className={className}>
        {preloadImage && (
          <img
            alt=""
            src={`/assets/${preloadImage}`}
            style={{ visibility: 'hidden' }}
          />
        )}
      </div>
    )
  }
}

const ImageStyled = styled(Image)`
  background-image: url(/assets/${props => props.imagePath});
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
`

class ImagePanel extends React.Component {
  render() {
    const { location } = this.props
    const imagePathKeys = Object.keys(imagePaths)
    return (
      <Panel>
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames="image-fade"
            timeout={250}
          >
            <Switch location={location}>
              {imagePathKeys.map((key, index) => (
                <Route
                  exact
                  key={key}
                  path={key}
                  render={props => (
                    <ImageStyled
                      key={key}
                      imagePath={imagePaths[key]}
                      nextImage={imagePaths[imagePathKeys[index + 1]]}
                    />
                  )}
                />
              ))}
              <Route
                exact
                render={props => <ImageStyled imagePath={imagePaths['/']} />}
              />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </Panel>
    )
  }
}

export default withRouter(ImagePanel)
