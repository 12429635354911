import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default class NumberStepper extends React.Component {
  onStep = dir => e => {
    const { max, min, onStep, value } = this.props
    let nextValue = value + dir
    if (min) nextValue = Math.max(nextValue, min)
    if (max) nextValue = Math.min(nextValue, max)

    onStep(nextValue)
  }

  render() {
    const { max, min, onChange, value } = this.props

    const classes = classNames('NumberStepper')
    return (
      <div className={classes}>
        <button
          className="NumberStepper-dn Icons-expand-up"
          onClick={this.onStep(-1)}
          type="button"
        />
        <input
          type="number"
          className="NumberStepper-value"
          {...{ max, min, onChange, value }}
        />
        <button
          className="NumberStepper-up Icons-expand-down"
          onClick={this.onStep(1)}
          type="button"
        />
      </div>
    )
  }
}

NumberStepper.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onStep: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
