import Button from './ui/Button'
import CheckBox from './ui/CheckBox'
import FormPageIndicator from './FormPageIndicator'
import React from 'react'
import styled from 'styled-components'

const SectionHeader = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`

const SectionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &:not(:last-child) {
    border-bottom: #ddd 1px dashed;
  }
`

const SectionCol = styled.div`
  flex: 2 1 auto;
  padding: 20px 0;
  color: #444;

  &:first-child:not(.name) {
    flex: 0 0 30%;
    color: #999;
  }
`

const ButtonEdit = styled.button`
  align-items: center;
  color: #444;
  cursor: pointer;
  display: inline-flex;
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;

  &:focus {
    outline: 0;
  }
`

const ButtonIcon = styled.div`
  align-items: center;
  background-color: #ddd;
  border-radius: 50%;
  color: white;
  display: inline-flex;
  font-size: 16px;
  height: 38px;
  justify-content: center;
  margin-left: 10px;
  padding: 0 0 0 2px;
  transition: all 0.3s ease;
  width: 38px;

  ${ButtonEdit}:hover & {
    background-color: #f45372;
    margin-left: 15px;
    transform: scale(1.1);
  }
`

const Review = props => {
  const { isSubmitting, errors } = props
  const {
    landlord,
    addressLine_1,
    addressLine_2,
    addressLine_3,
    addressLine_4,
    city,
    postcode,
  } = props.values

  const createAddress = addressLines =>
    addressLines.filter(item => !!item).join(', ')

  const landlordAddressLines = [
    landlord.addressLine_1,
    landlord.addressLine_2,
    landlord.addressLine_3,
    landlord.addressLine_4,
    landlord.city,
    landlord.postcode,
  ]

  const propertyAddressLines = [
    addressLine_1,
    addressLine_2,
    addressLine_3,
    addressLine_4,
    city,
    postcode,
  ]

  const today = new Date()

  const v8_start = new Date('2022-01-01T00:00:00Z')

  const ToastError = props => {
    return (
      <div className="Toasts">
        <div className="Toasts-items">
          <div className="Toasts-item u-accel" data-dismiss="false">
            <strong>Error!</strong> {props.message}
          </div>
        </div>
      </div>
    )
  }

  const displayServerError = () => {
    const requestErrorMessage = `There was an error processing your request,
    please try again. If this problem persists, please contact
    our customer support team on 0333 200 2541.`
    const submissionErrorMessage = `There was a problem processing your offer.
    Please review the information below, try again and contact customer
    services on 0333 200 2541 if the problem persists.`
    if (errors.requestError) {
      return <ToastError message={requestErrorMessage} />
    }
    if (errors.submissionError) {
      return <ToastError message={submissionErrorMessage} />
    }
  }

  return (
    <>
      <div className="wrap-content u-push-large">
        <h1>Review</h1>
        <p>
          Please review and send the information below, make sure your tenant’s
          email address and monthly rental amount are correct.
        </p>
        <SectionHeader>
          <h4 className="u-float-left">Landlord</h4>
          <ButtonEdit
            type="button"
            name="landlord"
            onClick={props.handleEditing}
          >
            Edit
            <ButtonIcon className="Icons-edit" />
          </ButtonEdit>
        </SectionHeader>
        <div className="u-push-3">
          <SectionRow>
            <SectionCol>Name</SectionCol>
            <SectionCol>{props.values.landlord.name}</SectionCol>
          </SectionRow>
          <SectionRow>
            <SectionCol>Address</SectionCol>
            <SectionCol>{createAddress(landlordAddressLines)}</SectionCol>
          </SectionRow>
          <SectionRow>
            <SectionCol>Phone</SectionCol>
            <SectionCol>{props.values.landlord.phoneNumber}</SectionCol>
          </SectionRow>
          <SectionRow>
            <SectionCol>Email</SectionCol>
            <SectionCol>{props.values.landlord.email}</SectionCol>
          </SectionRow>
        </div>

        <SectionHeader className="u-push-3">
          <h4 className="u-float-left">Property</h4>
          <ButtonEdit
            type="button"
            name="property"
            onClick={props.handleEditing}
          >
            Edit
            <ButtonIcon className="Icons-edit" />
          </ButtonEdit>
        </SectionHeader>
        <div className="u-push-3">
          <SectionRow>
            <SectionCol>Tenancy Start</SectionCol>
            <SectionCol>
              {new Date(props.values.tenancyStart).toLocaleDateString('en-GB')}
            </SectionCol>
          </SectionRow>
          <SectionRow>
            <SectionCol>Monthly Rent</SectionCol>
            <SectionCol>£{props.values.amount}</SectionCol>
          </SectionRow>
          <SectionRow>
            <SectionCol>Address</SectionCol>
            <SectionCol>{createAddress(propertyAddressLines)}</SectionCol>
          </SectionRow>
        </div>

        <SectionHeader className="u-push-3">
          <h4 className="u-float-left">
            Tenant
            {props.values.tenants.length > 1 ? 's' : null}
          </h4>
          <ButtonEdit
            type="button"
            name="tenants"
            onClick={props.handleEditing}
          >
            Edit
            <ButtonIcon className="Icons-edit" />
          </ButtonEdit>
        </SectionHeader>
        <div className="u-push-3">
          {props.values.tenants.map((tenant, index) => (
            <React.Fragment key={`review${index}`}>
              <SectionRow
                className={index > 0 ? 'u-push-large' : null}
                key={`review${index}`}
              >
                <SectionCol className="name">
                  {tenant.firstName} {tenant.lastName}
                </SectionCol>
              </SectionRow>
              <SectionRow>
                <SectionCol>Email</SectionCol>
                <SectionCol>{tenant.email}</SectionCol>
              </SectionRow>
              <SectionRow>
                <SectionCol>Date of Birth</SectionCol>
                <SectionCol>
                  {new Date(tenant.dateOfBirth).toLocaleDateString('en-GB')}
                </SectionCol>
              </SectionRow>
              {tenant.guarantor ? (
                <>
                  <SectionRow>
                    <SectionCol>Guarantor</SectionCol>
                    <SectionCol>
                      {tenant.guarantor.firstName} {tenant.guarantor.lastName}
                    </SectionCol>
                  </SectionRow>
                  <SectionRow>
                    <SectionCol>Guarantor email</SectionCol>
                    <SectionCol>{tenant.guarantor.email}</SectionCol>
                  </SectionRow>
                  <SectionRow>
                    <SectionCol>Guarantor Address</SectionCol>
                    <SectionCol>
                      {createAddress([
                        tenant.guarantor.addressLine_1,
                        tenant.guarantor.addressLine_2,
                        tenant.guarantor.addressLine_3,
                        tenant.guarantor.addressLine_4,
                        tenant.guarantor.city,
                        tenant.guarantor.postcode,
                      ])}
                    </SectionCol>
                  </SectionRow>
                </>
              ) : null}
            </React.Fragment>
          ))}
        </div>
        {displayServerError()}

        <CheckBox
          className="u-push-3 u-block"
          name="passedReferencesCheck"
          checked={props.passedReferencesCheck}
          onChange={props.handlePassedReferencesCheck}
        >
          I have referenced the tenant(s) and they have passed. I acknowledge
          that I will need to provide a copy of the reference if I need to make
          a claim. Failure to do so may lead to my cover being invalid or my
          claim declined.
        </CheckBox>

        <CheckBox
          className="u-push-3 u-block"
          name="termsAndConditions"
          onChange={props.handleTermsAndConditions}
          checked={props.termsAndConditions}
        >
          I accept and agree to the Zero Deposit{' '}
          <a
            href={
              today > v8_start ? '/assets/ERIL-ToB.pdf' : '/assets/ZD-ToB.pdf'
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Business.
          </a>
        </CheckBox>

        <Button
          type="submit"
          className="u-push-large"
          variant="main"
          fullWidth
          disabled={
            isSubmitting ||
            (!props.termsAndConditions || !props.passedReferencesCheck)
          }
        >
          Send
        </Button>
        <div className="flex-column">
          <FormPageIndicator />
        </div>
      </div>
    </>
  )
}

export default Review
