import React from 'react'
import Button from './ui/Button'
import PointList, { PointListItem } from './ui/PointList'
import styled from 'styled-components'
import Header from './Header'

const StyledLink = styled.a`
  text-decoration: none;
  font-weight: bold;
`

const Landing = ({ history }) => {
  return (
    <>
      <Header />
      <div className="wrap-content u-push-large">
        <h1>Offer Zero Deposit</h1>
        <p>You can offer the choice of Zero Deposit in three easy steps. </p>
        <PointList className="u-push-large">
          <PointListItem after={1}>
            <b>Tell us about the tenancy</b> <br />
            Enter some basic information about yourself, your tenants and your
            rental property.
          </PointListItem>
          <PointListItem after={2}>
            <b>Tenants choose Zero Deposit </b> <br />
            We’ll email your tenants with everything they need to purchase a
            Zero Deposit Guarantee.
          </PointListItem>
          <PointListItem after={3}>
            <b>Sign the tenancy update document</b> <br />
            As soon as your tenants purchase, we’ll let you know. You should
            have all parties sign the{' '}
            <StyledLink
              href="/assets/ZD-Tenancy-Update.pdf"
              rel="noopener noreferrer"
              target="_blank"
              className="u-col-main"
            >
              Tenancy Update Document{' '}
            </StyledLink>
            alongside your AST.
          </PointListItem>
        </PointList>
        <Button
          className="u-push-3"
          fullWidth
          onClick={e => history.push('/form/landlord')}
          variant="main"
        >
          Next
        </Button>
      </div>
    </>
  )
}

export default Landing
