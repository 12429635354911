import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'

const Dot = styled.div`
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  transition: all 0.2s ease;
  width: 8px;

  &.active {
    background-color: #f45372;
    transform: scale(1.2);
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
`

export default class PageIndicator extends React.Component {
  render() {
    const { routes, path } = this.props
    return (
      <div className="u-push-3">
        {routes.map(route => (
          <Dot
            key={route}
            className={classNames({
              active: route.indexOf(path) !== -1,
            })}
          />
        ))}
      </div>
    )
  }
}

PageIndicator.propTypes = {
  routes: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
}
