import Button from './ui/Button'
import FormPageIndicator from './FormPageIndicator'
import NumberStepper from './ui/NumberStepper'
import RadioGroup, { Radio } from './ui/RadioGroup'
import React from 'react'
import Select from './ui/Select'
import styled from 'styled-components'
import { Field } from 'formik'
import { TENANT_COUNT_MAX, TENANT_COUNT_MIN } from 'utils/constants'
import { withContentContainer } from '../ContentContainerContext'
import { withRouter } from 'react-router-dom'

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 150px;
`

const References = props => {
  const { passedReferences, referenceCompany } = props.values
  const {
    errors,
    focusFirstErrorInContent,
    history,
    numberOfTenants,
    onStepNumberOfTenants,
    setNumberOfTenants,
    setTouched,
    touched,
    referenceProviders,
  } = props
  return (
    <>
      <div className="wrap-content u-push-large">
        <h1>Number of Tenants</h1>
        <p>
          Tell us how many tenants will be listed on your AST. Then confirm they
          have all passed referencing.
        </p>
        <p className="u-push-large">Number of tenants renting the property</p>

        <NumberStepper
          value={numberOfTenants}
          max={TENANT_COUNT_MAX}
          min={TENANT_COUNT_MIN}
          onChange={setNumberOfTenants}
          onStep={onStepNumberOfTenants}
        />

        <div className="u-push-large">
          <label>Have all tenants passed referencing?</label>

          <div>
            <Field
              name="passedReferences"
              render={({ field }) => (
                <StyledRadioGroup
                  {...field}
                  error={
                    touched.passedReferences && errors.passedReferences
                      ? errors.passedReferences
                      : null
                  }
                  selectedValue={passedReferences}
                >
                  <Radio value={true} id="passedReferencesTrue">
                    Yes
                  </Radio>
                  <Radio
                    className="u-block"
                    value={'with guarantor'}
                    id="passedReferencesWithGuarantor"
                  >
                    Yes - with guarantor
                  </Radio>
                  <Radio
                    className="u-block"
                    value={false}
                    id="passedReferencesFalse"
                  >
                    No
                  </Radio>
                </StyledRadioGroup>
              )}
            />
          </div>
        </div>

        {passedReferences && (
          <div className="u-push-large">
            <p>Which company provided the reference?</p>

            <Field
              name="referenceCompany"
              render={({ field }) => (
                <Select
                  fullWidth={true}
                  {...field}
                  label="Reference company name"
                  placeholder="Select a company"
                  error={
                    touched.referenceCompany && errors.referenceCompany
                      ? errors.referenceCompany
                      : null
                  }
                >
                  {referenceProviders.map((referenceCompany, index) => {
                    return (
                      <option key={`ref${index}`} value={referenceCompany}>
                        {referenceCompany}
                      </option>
                    )
                  })}
                </Select>
              )}
            />
            <p>
              If your provider does not appear on our list please email
              <a href="mailto:compliance@zerodeposit.com">
                {' '}
                compliance@zerodeposit.com{' '}
              </a>
              with the details of the provider.
            </p>
          </div>
        )}

        {passedReferences === false && (
          <div className="TextWell u-push-3">
            You must reference all your tenants in order to offer the choice of
            Zero Deposit.
          </div>
        )}
        <div className="flex-column">
          <Button
            className="u-push-large u-block"
            disabled={passedReferences === false}
            fullWidth
            onClick={() => {
              if (!passedReferences) {
                setTouched({ passedReferences: true })
                focusFirstErrorInContent()
              } else if (!referenceCompany) {
                setTouched({ referenceCompany: true })
                focusFirstErrorInContent()
              } else {
                history.push('/form/tenants')
              }
            }}
            variant="main"
          >
            Next
          </Button>
          <FormPageIndicator />
        </div>
      </div>
    </>
  )
}

export default withRouter(withContentContainer(References))
